/* eslint-disable linebreak-style */
/* eslint-disable default-param-last */
// @flow

export const initialState = {
  isLoading: false,
  isLoggedIn: false,
  openLogin: false,
  openFilters: false,
  loginStage: 1,
  token: null,
  loginToken: null,
  referralLink: null,
  referredByCode: null,
  showedPopup: false,
  dailyPopup: false,
  surveyPopup: false,
  user: {},
  alert: null,
  events: [],
  bands: [],
  locations: [],
  highlights: [],
  restaurants: [],
  eventTypes: [],
  bandTypes: [],
  genres: [],
  otherData: [],
  interests: [],
  sponsors: [],
  cities: [],
  localities: [],
  giveaways: [],
  surveys: [],
  filters: {
    date: [],
    type: [],
    genre: [],
    location: [],
    price: [],
    sponsor: [],
    distance: [],
    city: [],
    neighbourhood: [],
  },
  searchFilters: {
    when: [],
    type: [],
  },
  isArtist:false,
  isFan:false,
  isProducer:false
};

export const SET_USER_TYPE = 'AppState/SET_USER_TYPE';
export const SET_EVENTS = 'AppState/SET_EVENTS';
export const SET_BANDS = 'AppState/SET_BANDS';
export const SET_LOCATIONS = 'AppState/SET_LOCATIONS';
export const SET_HIGHLIGHTS = 'AppState/SET_HIGHLIGHTS';
export const SET_RESTAURANTS = 'AppState/SET_RESTAURANTS';
export const SET_EVENT_TYPES = 'AppState/SET_EVENT_TYPES';
export const SET_BAND_TYPES = 'AppState/SET_BAND_TYPES';
export const SET_GENRES = 'AppState/SET_GENRES';
export const SET_INTERESTS = 'AppState/SET_INTERESTS';
export const SET_OTHER_DATA = 'AppState/SET_OTHER_DATA';
export const SET_SPONSORS = 'AppState/SET_SPONSORS';
export const SET_CITIES = 'AppState/SET_CITIES';
export const SET_LOCALITIES = 'AppState/SET_LOCALITIES';
export const SET_GIVEAWAYS = 'AppState/SET_GIVEAWAYS';
export const SET_SURVEYS = 'AppState/SET_SURVEYS';
export const SET_FILTERS = 'AppState/SET_FILTERS';

export const SET_SEARCH_FILTERS = 'AppState/SET_SEARCH_FILTERS';

export const SET_LOADING = 'AppState/SET_LOADING';
export const SET_OPEN_LOGIN = 'AppState/SET_OPEN_LOGIN';
export const SET_OPEN_FILTERS = 'AppState/SET_OPEN_FILTERS';
export const SET_LOGIN_STAGE = 'AppState/SET_LOGIN_STAGE';
export const SET_LOGIN_TOKEN = 'AppState/SET_LOGIN_TOKEN';
export const SET_REFERRAL_LINK = 'AppState/SET_REFERRAL_LINK';
export const SET_REFERRED_BY_CODE = 'AppState/SET_REFERRED_BY_CODE';
export const SET_SHOWED_POPUP = 'AppState/SET_SHOWED_POPUP';
export const SET_DAILY_POPUP = 'AppState/SET_DAILY_POPUP';
export const SET_SURVEY_POPUP = 'AppState/SET_SURVEY_POPUP';
export const SET_USER_ACTIVE = 'AppState/SET_USER_ACTIVE';
export const LOG_IN = 'AppState/LOG_IN';
export const LOG_OUT = 'AppState/LOG_OUT';
export const SET_ALERT = 'AppState/SET_ALERT';
export const UPDATE_USER = 'AppState/UPDATE_USER';

export function setEvents(events) {
  return {
    type: SET_EVENTS,
    payload: events,
  };
}

export function setBands(bands) {
  return {
    type: SET_BANDS,
    payload: bands,
  };
}

export function setLocations(locations) {
  return {
    type: SET_LOCATIONS,
    payload: locations,
  };
}

export function setHighlights(highlights) {
  return {
    type: SET_HIGHLIGHTS,
    payload: highlights,
  };
}

export function setRestaurants(restaurants) {
  return {
    type: SET_RESTAURANTS,
    payload: restaurants,
  };
}

export function setEventTypes(eventTypes) {
  return {
    type: SET_EVENT_TYPES,
    payload: eventTypes,
  };
}

export function setBandTypes(bandTypes) {
  return {
    type: SET_BAND_TYPES,
    payload: bandTypes,
  };
}

export function setGenres(genres) {
  return {
    type: SET_GENRES,
    payload: genres,
  };
}

export function setOtherData(otherData) {
  return {
    type: SET_OTHER_DATA,
    payload: otherData,
  };
}

export function setInterests(interests) {
  return {
    type: SET_INTERESTS,
    payload: interests,
  };
}

export function setUserType(userType) {
  return {
    type: SET_USER_TYPE,
    payload: userType,
  };
}

export function setSponsors(sponsors) {
  return {
    type: SET_SPONSORS,
    payload: sponsors,
  };
}

export function setCities(cities) {
  return {
    type: SET_CITIES,
    payload: cities,
  };
}

export function setLocalities(localities) {
  return {
    type: SET_LOCALITIES,
    payload: localities,
  };
}

export function setGiveaways(giveaways) {
  return {
    type: SET_GIVEAWAYS,
    payload: giveaways,
  };
}

export function setSurveys(surveys) {
  return {
    type: SET_SURVEYS,
    payload: surveys,
  };
}

export function setFilters(filter) {
  return {
    type: SET_FILTERS,
    payload: filter,
  };
}

export function setSearchFilters(searchFilter) {
  return {
    type: SET_SEARCH_FILTERS,
    payload: searchFilter,
  };
}

export function setLoading(status = false) {
  return {
    type: SET_LOADING,
    payload: status,
  };
}

export function setOpenLogin(openLogin) {
  return {
    type: SET_OPEN_LOGIN,
    payload: openLogin,
  };
}

export function setOpenFilters(openFilters) {
  return {
    type: SET_OPEN_FILTERS,
    payload: openFilters,
  };
}

export function setLoginStage(loginStage) {
  return {
    type: SET_LOGIN_STAGE,
    payload: loginStage,
  };
}

export function setLoginToken(loginToken) {
  return {
    type: SET_LOGIN_TOKEN,
    payload: loginToken,
  };
}

export function setReferralLink(referralLink) {
  return {
    type: SET_REFERRAL_LINK,
    payload: referralLink,
  };
}

export function setReferredByCode(referredByCode) {
  return {
    type: SET_REFERRED_BY_CODE,
    payload: referredByCode,
  };
}

export function setShowedPopup(showedPopup) {
  return {
    type: SET_SHOWED_POPUP,
    payload: showedPopup,
  };
}

export function setDailyPopup(dailyPopup) {
  return {
    type: SET_DAILY_POPUP,
    payload: dailyPopup,
  };
}

export function setSurveyPopup(surveyPopup) {
  return {
    type: SET_SURVEY_POPUP,
    payload: surveyPopup,
  };
}

export function setUserLoggedIn(user, token) {
  return {
    type: LOG_IN,
    payload: {
      user,
      token,
    },
  };
}

export function updateUser(user) {
  return {
    type: UPDATE_USER,
    payload: user,
  };
}

export function setAlert(alert) {
  return {
    type: SET_ALERT,
    payload: alert,
  };
}

export function logout() {
  return {
    type: LOG_OUT,
  };
}

const AppStateReducer = (state = initialState, { type, payload }) => {
  switch (type) {
    case SET_USER_TYPE:
      return {
        ...state,
        isArtist: payload.isArtist,
        isFan: payload.isFan,
        isProducer: payload.isProducer,
      };
    case SET_EVENTS:
      return {
        ...state,
        events: payload,
      };
    case SET_BANDS:
      return {
        ...state,
        bands: payload,
      };
    case SET_LOCATIONS:
      return {
        ...state,
        locations: payload,
      };
    case SET_HIGHLIGHTS:
      return {
        ...state,
        highlights: payload,
      };
    case SET_RESTAURANTS:
      return {
        ...state,
        restaurants: payload,
      };
    case SET_EVENT_TYPES:
      return {
        ...state,
        eventTypes: payload,
      };
    case SET_BAND_TYPES:
      return {
        ...state,
        bandTypes: payload,
      };
    case SET_GENRES:
      return {
        ...state,
        genres: payload,
      };
    case SET_OTHER_DATA:
      return {
        ...state,
        otherData: payload,
      };
    case SET_INTERESTS:
      return {
        ...state,
        interests: payload,
      };
    case SET_SPONSORS:
      return {
        ...state,
        sponsors: payload,
      };
    case SET_CITIES:
      return {
        ...state,
        cities: payload,
      };
    case SET_LOCALITIES:
      return {
        ...state,
        localities: payload,
      };
    case SET_GIVEAWAYS:
      return {
        ...state,
        giveaways: payload,
      };
    case SET_SURVEYS:
      return {
        ...state,
        surveys: payload,
      };
    case SET_FILTERS:
      return {
        ...state,
        filters: payload,
      };
    case SET_SEARCH_FILTERS:
      return {
        ...state,
        searchFilters: payload,
      };
    case SET_LOADING:
      return {
        ...state,
        isLoading: payload,
      };
    case SET_OPEN_LOGIN:
      return {
        ...state,
        openLogin: payload,
      };
    case SET_OPEN_FILTERS:
      return {
        ...state,
        openFilters: payload,
      };
    case SET_LOGIN_STAGE:
      return {
        ...state,
        loginStage: payload,
      };
    case SET_LOGIN_TOKEN:
      return {
        ...state,
        loginToken: payload,
      };
    case SET_REFERRAL_LINK:
      return {
        ...state,
        referralLink: payload,
      };
    case SET_REFERRED_BY_CODE:
      return {
        ...state,
        referredByCode: payload,
      };
    case SET_SHOWED_POPUP:
      return {
        ...state,
        showedPopup: payload,
      };
    case SET_DAILY_POPUP:
      return {
        ...state,
        dailyPopup: payload,
      };
    case SET_SURVEY_POPUP:
      return {
        ...state,
        surveyPopup: payload,
      };
    case SET_ALERT:
      return {
        ...state,
        alert: payload,
      };
    case LOG_IN:
      return {
        ...state,
        isLoggedIn: true,
        user: payload.user,
        token: payload.token,
      };
    case LOG_OUT:
      return {
        ...state,
        isLoggedIn: false,
        user: {},
        token: null,
      };
    case UPDATE_USER:
      return {
        ...state,
        user: payload,
      };
    default:
      return state;
  }
};

export default AppStateReducer;
