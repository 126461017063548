import { React } from 'react';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import '../../../style/Login/login.css';
import { setOpenLogin } from '../../../state/AppState';

function Welcome({ setOpenLoginState, isArtist, isProducer }) {
  return (
    <div className="welcome">
      <div className="welcomeContainer">
        <div className="welcomeText">
          <h2>¡Gracias por registrarte en Voy!</h2>
          <p>Ya podés acceder a toda la oferta de espectáculos y eventos culturales.</p>
        </div>

        <div className="buttonsWelcome">
          {(isArtist || isProducer) && (
            <a
              href="https://api.voy.com.uy/administrators/view-login"
              target="_blank"
              rel="noopener noreferrer"
              className="createEventButton"
            >
              CREAR EVENTOS
            </a>
          )}

          <Link
            to="/"
            className={`discoverEventsButton ${!(isArtist || isProducer) ? 'highlight' : ''}`}
            onClick={() => setOpenLoginState(false)}
          >
            DESCUBRIR EVENTOS
          </Link>

          <Link
            to="/user"
            className="viewProfileButton"
            onClick={() => setOpenLoginState(false)}
          >
            VER MI PERFIL
          </Link>
        </div>
      </div>
    </div>
  );
}

const mapStateToProps = (state) => ({
  isArtist: state.app.isArtist,
  isProducer: state.app.isProducer,
});

export default connect(mapStateToProps, {
  setOpenLoginState: setOpenLogin,
})(Welcome);
