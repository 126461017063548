import { React, useState, useEffect, useRef } from 'react';
import '../../style/Login/login.css';
import { useForm } from 'react-hook-form';
import { useNavigate } from 'react-router-dom';
import { connect } from 'react-redux';
import axios from 'axios';
import {
  setOpenLogin, setLoginToken, setLoginStage, setReferralLink, setShowedPopup, setLoading,
} from '../../state/AppState';
import RegisterLogin from './components/registerLogin';
import Soy from './components/soy';
import TypeEvent from './components/typeEvents';
import Welcome from './components/Welcome';
import { endpointLogin6 } from '../../endpoints';

function Login({
  openLogin, setOpenLoginState, loginStage, loginToken,
  setLoginStageState, setLoginTokenState, setShowedPopupState,
}) {
  const { register, handleSubmit, setError, formState: { errors } } = useForm();
  const [loading, setLoading] = useState(false);
  const [menuOpen, setMenuOpen] = useState(false);
  const menuRef = useRef(null);
  const navigate = useNavigate();

  const handleLogIn = async (data) => {
    setLoading(true);
    try {
      const res = await axios.post(endpointLogin6, { email: data.email, password: data.password });
      if (res.data.status === 'ok') {
        localStorage.setItem('sessionToken', res.data.token);
        setLoginTokenState(res.data.token);
        setOpenLoginState(false);
        setLoading(false);
        navigate('/user');
      } else {
        setLoading(false);
        setError("email", {
          type: "manual",
          message: "Correo electrónico o contraseña incorrecta.",
        });
      }
    } catch (error) {
      setLoading(false);
      console.error("Error:", error);
    }
  };

  const handleLogout = () => {
    localStorage.removeItem('sessionToken');
    setLoginTokenState(null);
    setMenuOpen(false);
    navigate('/');
  };

  // Cierra el menú cuando se hace click fuera
  useEffect(() => {
    function handleClickOutside(event) {
      if (menuRef.current && !menuRef.current.contains(event.target)) {
        setMenuOpen(false);
      }
    }
    
    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [menuRef]);

  // Toggle para el menú en lugar de hover
  const toggleMenu = () => {
    setMenuOpen(!menuOpen);
  };

  function renderSwitch() {
    switch (loginStage) {
      case 1:
        return (
          <div className="loginPopupContainer">
            <p className="titleLoginRegister">Iniciar sesión</p>
            <p className="accessEvents">Accedé a los eventos y espectáculos que marcan tendencia.</p>
            <form className="registerForm" onSubmit={handleSubmit(handleLogIn)}>
              <div className={`inputContainer ${errors.email ? "has-error" : ""}`}>
                <input
                  {...register('email', { required: '*El campo de email es obligatorio' })}
                  type="email"
                  className={`login-input ${errors.email ? 'input-error' : ''}`}
                  placeholder="Email"
                />
                <p className="error-message">{errors.email?.message}</p>
              </div>

              <div className={`inputContainer ${errors.password ? "has-error" : ""}`}>
                <input
                  {...register('password', { required: '*El campo de contraseña es obligatorio' })}
                  type="password"
                  className={`login-input ${errors.password ? 'input-error' : ''}`}
                  placeholder="Contraseña"
                />
                <p className="error-message">{errors.password?.message}</p>
              </div>

              <div className="forgotPassword">
                <a href="#" style={{ textDecoration: 'underline', color: '#FFFFFF' }}>Olvidé mi contraseña</a>
              </div>

              <button type="submit" className="apiLoginButton">INICIAR SESIÓN</button>
              <div className="separator">
                <span>ó</span>
              </div>
              <button type="button" className="createAccountButton" onClick={() => setLoginStageState(2)}>CREAR UNA CUENTA</button>
            </form>
          </div>
        );

      case 2:
        return <RegisterLogin handleContinue={() => setLoginStageState(3)} goToLogin={() => setLoginStageState(1)} />;
      case 3:
        return <Soy handleContinue={() => setLoginStageState(4)} />;
      case 4:
        return <TypeEvent handleContinue={() => setLoginStageState(5)} />;
      case 5:
        return <Welcome loginToken={loginToken} />;
      default:
        return null;
    }
  }

  return (
    <>
      {loginToken ? (
        <div className="profile-container" ref={menuRef}>
          <button type="button" className="loginButton user" onClick={toggleMenu}>
            <span className="desktopElement">Mi Perfil</span>
            <span className="mobileElement">
              <div className="hamburger-icon">
                <span></span>
                <span></span>
                <span></span>
              </div>
            </span>
          </button>
          {menuOpen && (
            <div className="dropdown-menu">
              <button className="dropdown-item" onClick={() => navigate('/user')}>Editar mi perfil</button>
              <button className="logout-button" onClick={handleLogout}>Cerrar Sesión</button>
            </div>
          )}
        </div>
      ) : (
        <button
          type="button"
          onClick={() => {
            setOpenLoginState(!openLogin);
            setLoginStageState(1);
            setShowedPopupState(true);
          }}
          className={openLogin ? 'loginButton openLogin user' : 'loginButton user'}
        >
          <div className="desktopElement">Iniciar Sesión | Registrarme</div>
          <div className="mobileElement">
            <div className="hamburger-icon">
              <span></span>
              <span></span>
              <span></span>
            </div>
          </div>
        </button>
      )}

      {openLogin && (
        <div className="loginBase">
          <div className={`containerLoginBase ${loginStage === 1 ? "login" : loginStage === 5 ? "welcome" : "register"}`}>
            <div className="imageContainer"></div>
            <div className="subContainerLoginBase">
              <button type="button" onClick={() => setOpenLoginState(false)} className="closeLogin">✖</button>
              <div className="containerInformationLogin">{renderSwitch()}</div>
            </div>
          </div>
        </div>
      )}
    </>
  );
}

const mapStateToProps = (state) => ({
  isLoading: state.app.isLoading,
  openLogin: state.app.openLogin,
  loginStage: state.app.loginStage,
  loginToken: state.app.loginToken,
});

export default connect(mapStateToProps, {
  setOpenLoginState: setOpenLogin,
  setLoginTokenState: setLoginToken,
  setLoginStageState: setLoginStage,
  setReferralLinkState: setReferralLink,
  setShowedPopupState: setShowedPopup,
  setLoadingState: setLoading,
})(Login);