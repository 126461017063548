import { React, useState } from 'react';
import { connect } from 'react-redux';
import axios from 'axios';
import { endpointLogin3 } from '../../../endpoints';
import { setUserType, setOpenLogin } from '../../../state/AppState';
import '../../../style/Login/login.css';

const Soy = ({ handleContinue, setUserTypeState, setOpenLoginState }) => {
  const [userType, setUserTypeLocal] = useState(null);
  const [loading, setLoading] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");

  const handleUserTypeSubmit = async () => {
    setLoading(true);
    setErrorMessage("");

    const token = localStorage.getItem('sessionToken');
    if (!token) {
      setErrorMessage("Error: No hay token de sesión. Intenta registrarte nuevamente.");
      setLoading(false);
      return;
    }

    const payload = {
      token,
      is_fan: userType === "fan" ? 1 : 0,
      is_artist: userType === "artist" ? 1 : 0,
      is_producer: userType === "producer" ? 1 : 0,
      has_venue: userType === "producer" ? 1 : 0, //dudas aca
      band_name: "", //Se llena en otro paso?
      venue_name: "", //Se llena en otro paso?
    };

    try {
      const response = await axios.post(endpointLogin3, payload);
      console.log("User type set response:", response.data);

      if (response.data.status === "ok") {
        setUserTypeState({
          isArtist: userType === "artist",
          isFan: userType === "fan",
          isProducer: userType === "producer",
        });

        handleContinue();
      } else {
        setErrorMessage(response.data.errorMessage || "Error al guardar el tipo de usuario.");
      }
    } catch (error) {
      console.error("Error setting user type:", error);
      setErrorMessage("Error de conexión con el servidor.");
    } finally {
      setLoading(false);
    }
  };

  return (
    <div className="soyContainer">
      <p className="soyTitle">Seleccioná qué tipo de usuario eres.</p>

      <div className="soyOptions">
        <button
          type="button"
          className={`soyButton ${userType === "fan" ? "selected" : ""}`}
          onClick={() => setUserTypeLocal("fan")}
        >
          Soy Fan
        </button>
        <button
          type="button"
          className={`soyButton ${userType === "producer" ? "selected" : ""}`}
          onClick={() => setUserTypeLocal("producer")}
        >
          Soy Productor/Programador
        </button>
        <button
          type="button"
          className={`soyButton ${userType === "artist" ? "selected" : ""}`}
          onClick={() => setUserTypeLocal("artist")}
        >
          Soy Artista
        </button>
      </div>

      {errorMessage && <p className="error-message">{errorMessage}</p>}

      <button
        type="button"
        className="continueLoginButton"
        onClick={handleUserTypeSubmit}
        disabled={!userType || loading}
      >
        {loading ? "Cargando..." : "CONTINUAR"}
      </button>

      <p className="skipOption" onClick={() => setOpenLoginState(false)}>Lo voy a hacer más tarde</p>
    </div>
  );
};

const mapDispatchToProps = (dispatch) => ({
  setUserTypeState: (userType) => dispatch(setUserType(userType)),
  setOpenLoginState: (isOpen) => dispatch(setOpenLogin(isOpen)), // Agregado
});

export default connect(null, mapDispatchToProps)(Soy);
