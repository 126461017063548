import React, { useEffect, useState } from 'react';
import { Spin, message } from 'antd';
import axios from 'axios';
import {
  endpointUser1,
  endpointUserUpdate,
  endpointFrontData,
  endpointUserChangePasswordFront,
} from '../../endpoints';
import '../../style/Users/profile.css';
import Header from '../Layouts/Header';
import Footer from '../Layouts/Footer';
import UserInterests from './components/UserInterests';

const User = () => {
  const token = localStorage.getItem('sessionToken');
  const [loading, setLoading] = useState(false);
  const [user, setUser] = useState({ firstName: "", lastName: "", email: "", userType: {} });
  const [selectedInterests, setSelectedInterests] = useState({});
  const [eventTypes, setEventTypes] = useState([]);
  const [genres, setGenres] = useState([]);
  const [interestsReady, setInterestsReady] = useState(false);

  // Contraseña
  const [currentPassword, setCurrentPassword] = useState("");
  const [newPassword, setNewPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");

  useEffect(() => {
    if (token) {
      fetchAllData();
    } else {
      message.error("No hay sesión activa.");
    }
  }, [token]);

  const fetchAllData = async () => {
    setLoading(true);
    setInterestsReady(false);
    try {
      const [userRes, frontDataRes] = await Promise.all([
        axios.post(endpointUser1, { token }),
        axios.get(endpointFrontData),
      ]);

      const userData = userRes.data;
      const frontData = frontDataRes.data;

      setUser({
        firstName: userData.firstName || "",
        lastName: userData.lastName || "",
        email: userData.email || "",
        userType: userData.userType || {},
      });

      const interestsObject = Object.keys(userData.interests || {}).reduce((acc, key) => {
        const interest = userData.interests[key];
        acc[key] = {
          isSelected: interest.isSelected,
          genres: (interest.genres || []).map(Number),
        };
        return acc;
      }, {});
      setSelectedInterests(interestsObject);
      setEventTypes(frontData.eventTypesForInterests || []);
      setGenres(frontData.genres || []);
      setInterestsReady(true);
    } catch (error) {
      console.error("Error al obtener datos:", error);
      message.error('Error al obtener los datos necesarios');
    } finally {
      setLoading(false);
    }
  };

  const validateEmail = (email) => {
    const re = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    return re.test(email);
  };

  const handleUserTypeChange = (type) => {
    setUser((prev) => ({
      ...prev,
      userType: {
        isFan: type === "Fan",
        isArtist: type === "Artista",
        isVenueOwner: type === "Productor",
      },
    }));
  };

  const handleSaveProfile = async () => {
    if (!user.firstName.trim() || !user.lastName.trim() || !user.email.trim()) {
      return message.warning("Nombre, apellido y correo no pueden estar vacíos.");
    }

    if (!validateEmail(user.email)) {
      return message.warning("Por favor ingresa un email válido.");
    }

    setLoading(true);
    try {
      const response = await axios.post(endpointUserUpdate, {
        token,
        firstName: user.firstName.trim(),
        lastName: user.lastName.trim(),
        email: user.email.trim(),
        userType: user.userType,
      });

      if (response.data.status === "ok") {
        message.success('Perfil actualizado con éxito');
        await fetchAllData();
      } else {
        message.error('Error al actualizar perfil');
      }
    } catch (error) {
      console.error(error);
      message.error('Error al actualizar perfil');
    } finally {
      setLoading(false);
    }
  };

  const handleChangePassword = async () => {
    if (!currentPassword || !newPassword || !confirmPassword) {
      return message.warning("Por favor completa todos los campos.");
    }

    if (newPassword.length < 6) {
      return message.warning("La nueva contraseña debe tener al menos 6 caracteres.");
    }

    if (newPassword === currentPassword) {
      return message.warning("La nueva contraseña no puede ser igual a la actual.");
    }

    if (newPassword !== confirmPassword) {
      return message.warning("La nueva contraseña y la confirmación no coinciden.");
    }

    setLoading(true);
    try {
      const response = await axios.post(endpointUserChangePasswordFront, {
        token,
        password: currentPassword,
        new_password: newPassword,
      });

      if (response.data.status === "ok") {
        message.success("Contraseña actualizada correctamente");
        setCurrentPassword("");
        setNewPassword("");
        setConfirmPassword("");
      } else {
        message.error(response.data.errorMessage || "Error al cambiar contraseña");
      }
    } catch (error) {
      console.error("Error al cambiar contraseña:", error);
      message.error("Error inesperado al cambiar contraseña");
    } finally {
      setLoading(false);
    }
  };

  return (
    <>
      <Header />
      <div className="profileBackground">
        <div className="userProfileContainer">
          <h1 className="profileTitle">Editar mi perfil</h1>

          {loading ? (
            <Spin size="large" />
          ) : (
            <>
              <div className="profileSections">
                <div className="profileCard basicUserData">
                  <h3>Mis datos</h3>
                  <input
                    type="text"
                    className="profileInput"
                    placeholder="Nombre"
                    value={user.firstName}
                    onChange={(e) => setUser({ ...user, firstName: e.target.value })}
                  />
                  <input
                    type="text"
                    className="profileInput"
                    placeholder="Apellido"
                    value={user.lastName}
                    onChange={(e) => setUser({ ...user, lastName: e.target.value })}
                  />
                  <input
                    type="email"
                    className="profileInput"
                    placeholder="Email"
                    value={user.email}
                    onChange={(e) => setUser({ ...user, email: e.target.value })}
                  />
                  <button className="profileSaveButton" onClick={handleSaveProfile}>GUARDAR</button>
                </div>

                <div className="profileCard basicUserData">
                  <h3>Cambiar contraseña</h3>
                  <input
                    type="password"
                    className="profileInput"
                    placeholder="Actual"
                    value={currentPassword}
                    onChange={(e) => setCurrentPassword(e.target.value)}
                  />
                  <input
                    type="password"
                    className="profileInput"
                    placeholder="Nueva"
                    value={newPassword}
                    onChange={(e) => setNewPassword(e.target.value)}
                  />
                  <input
                    type="password"
                    className="profileInput"
                    placeholder="Confirmación"
                    value={confirmPassword}
                    onChange={(e) => setConfirmPassword(e.target.value)}
                  />
                  <button className="profileSaveButton" onClick={handleChangePassword}>GUARDAR</button>
                </div>
              </div>

              <div className="profileSections">
                <div className="profileCard userTypeSection">
                  <h3>Tipo de usuario</h3>
                  <div className="userTypeOptions">
                    <button className={`userTypeButton ${user.userType.isFan ? "selected" : ""}`} onClick={() => handleUserTypeChange("Fan")}>Soy Fan</button>
                    <button className={`userTypeButton ${user.userType.isArtist ? "selected" : ""}`} onClick={() => handleUserTypeChange("Artista")}>Soy Artista</button>
                    <button className={`userTypeButton ${user.userType.isVenueOwner ? "selected" : ""}`} onClick={() => handleUserTypeChange("Productor")}>Soy Productor</button>
                  </div>
                  <button className="profileSaveButton" onClick={handleSaveProfile}>GUARDAR</button>
                </div>
              </div>

              <div className="profileSection">
                {interestsReady ? (
                  <UserInterests
                    initialInterests={selectedInterests}
                    loginToken={token}
                    onSave={fetchAllData}
                    eventTypes={eventTypes}
                    genres={genres}
                  />
                ) : (
                  <p style={{ color: 'white' }}>Cargando intereses...</p>
                )}
              </div>
            </>
          )}
        </div>
      </div>
      <Footer />
    </>
  );
};

export default User;
