import { React, useState, useEffect } from 'react';
import '../../../style/Login/login.css';
import axios from 'axios';
import { connect } from 'react-redux';
import { endpointLogin4 } from '../../../endpoints';
import { setOpenLogin } from '../../../state/AppState';

const TypeEvent = ({ handleContinue, interests = {}, loginToken, setOpenLoginState }) => {
  const [selectedInterests, setSelectedInterests] = useState([]);
  const [newsletter, setNewsletter] = useState(false);

  useEffect(() => {
    console.log("Interests received:", interests);
  }, [interests]);

  const toggleInterest = (interestId) => {
    setSelectedInterests((prev) =>
      prev.includes(interestId)
        ? prev.filter((id) => id !== interestId)
        : [...prev, interestId]
    );
  };

  const submitInterests = async () => {
    try {
      const structuredInterests = selectedInterests.map((id) => ({
        eventTypeId: id,
        isSelected: true,
        genres: [],
      }));
  
      const response = await axios.post(endpointLogin4, {
        token: loginToken,
        interests: structuredInterests,
        newsletter: newsletter ? 1 : 0,
      });
  
      if (response.data.status === 'ok') {
        console.log('Interests saved successfully', response.data);
        handleContinue();
        setLoginStageState(5);
      } else {
        console.error('Error saving interests:', response.data.errorMessage);
      }
    } catch (error) {
      console.error('Error connecting to server:', error);
    }
  };
  

  return (
    <div className="typeEventContainer">
      <p className="typeEventTitle">Seleccioná qué tipo de eventos te interesan.</p>

      <div className="typeEventOptions">
        {Array.isArray(interests?.topInterests) && interests.topInterests.length > 0 ? (
          interests.topInterests.map((interest) => (
            <button
              key={interest.id}
              type="button"
              className={`typeEventButton ${selectedInterests.includes(interest.id) ? 'selected' : ''}`}
              onClick={() => toggleInterest(interest.id)}
            >
              {interest.name}
            </button>
          ))
        ) : (
          <p className="error-message">No hay eventos disponibles.</p>
        )}
      </div>

      {/* Newsletter */}
      {/* <div className="newsletterContainer">
        <label className="checkboxLabel">
          <input
            type="checkbox"
            checked={newsletter}
            onChange={() => setNewsletter(!newsletter)}
          />
          Quiero recibir recomendaciones de espectáculos
        </label>
      </div> */}

      {/* Botón continuar */}
      <button
        type="button"
        className="continueLoginButton"
        onClick={submitInterests}
        disabled={selectedInterests.length === 0}
      >
        CONTINUAR
      </button>

      <p className="skipOption" onClick={() => setOpenLoginState(false)}>Lo voy a hacer más tarde</p>
    </div>
  );
};

const mapStateToProps = (state) => ({
  loginToken: state.app.loginToken,
  interests: state.app.interests || {}, 
});

const mapDispatchToProps = (dispatch) => ({
  setOpenLoginState: (isOpen) => dispatch(setOpenLogin(isOpen)),
});

export default connect(mapStateToProps, mapDispatchToProps)(TypeEvent);
