import '../../../style/Login/login.css';
import { useForm } from 'react-hook-form';
import axios from 'axios';
import { endpointLogin1 } from '../../../endpoints';
import { connect } from 'react-redux';
import { setLoginToken } from '../../../state/AppState';

const RegisterLogin = ({ handleContinue, goToLogin, setLoginTokenState }) => {
  const { register, handleSubmit, setError, watch, formState: { errors } } = useForm();

  const onSubmit = async (data) => {
    console.log("Form submitted");

    if (data.password !== data.repeat_password) {
      setError("repeat_password", {
        type: "manual",
        message: "Las contraseñas no coinciden",
      });
      return;
    }

    console.log("Sending request to:", endpointLogin1);
    console.log("Data:", {
      email: data.email,
      password: data.password,
      first_name: data.first_name,
      last_name: data.last_name,
      referral_code: data.referral_code || "",
    });

    try {
      const response = await axios.post(endpointLogin1, {
        email: data.email,
        password: data.password,
        first_name: data.first_name,
        last_name: data.last_name,
        referral_code: data.referral_code || "",
      });

      console.log("Response received:", response.data);

      if (response.data.status === 'ok') {
        console.log("User registered successfully:", response.data);

        // Save token in localStorage
        localStorage.setItem('sessionToken', response.data.token);

        // Update Redux state with login token
        setLoginTokenState(response.data.token);

        // Move to the next step (without closing the popup)
        handleContinue();
      } else if (response.data.status === 'alreadyRegistered') {
        console.warn("Email already in use:", response.data);
        setError("email", {
          type: "manual",
          message: "El email ingresado ya se encuentra en uso",
        });
      } else {
        console.error("Error registering:", response.data);
        setError("general", {
          type: "manual",
          message: response.data.errorMessage || "Error desconocido al registrar",
        });
      }
    } catch (error) {
      console.error("Error registering user:", error);
      setError("general", {
        type: "manual",
        message: "Error de conexión con el servidor. Inténtalo de nuevo.",
      });
    }
  };

  return (
    <div className="RegisterLogin">
      <p className="titleLoginRegister">Registrarme</p>

      <form className="registerForm" onSubmit={handleSubmit(onSubmit)}>
        <div className="containerEmailLogin">
          <div className={`inputContainer ${errors.first_name ? "has-error" : ""}`}>
            <input 
              {...register('first_name', { required: '*El campo de nombre es obligatorio' })} 
              type="text" 
              className={`login-input ${errors.first_name ? 'input-error' : ''}`} 
              placeholder="Nombre" 
            />
            <p className="error-message">{errors.first_name?.message}</p>
          </div>

          <div className={`inputContainer ${errors.last_name ? "has-error" : ""}`}>
            <input 
              {...register('last_name', { required: '*El campo de apellido es obligatorio' })} 
              type="text" 
              className={`login-input ${errors.last_name ? 'input-error' : ''}`} 
              placeholder="Apellido" 
            />
            <p className="error-message">{errors.last_name?.message}</p>
          </div>

          <div className={`inputContainer ${errors.email ? "has-error" : ""}`}>
            <input 
              {...register('email', { required: '*El campo de email es obligatorio' })} 
              type="email" 
              className={`login-input ${errors.email ? 'input-error' : ''}`} 
              placeholder="Email" 
            />
            <p className="error-message">{errors.email?.message}</p>
          </div>

          <div className={`inputContainer ${errors.password ? "has-error" : ""}`}>
            <input 
              {...register('password', { required: '*El campo de contraseña es obligatorio' })} 
              type="password" 
              className={`login-input ${errors.password ? 'input-error' : ''}`} 
              placeholder="Contraseña" 
            />
            <p className="error-message">{errors.password?.message}</p>
          </div>

          <div className={`inputContainer ${errors.repeat_password ? "has-error" : ""}`}>
            <input 
              {...register('repeat_password', { 
                required: '*El campo de repetir contraseña es obligatorio',
                validate: (value) =>
                  value === watch("password") || "Las contraseñas no coinciden"
              })} 
              type="password" 
              className={`login-input ${errors.repeat_password ? 'input-error' : ''}`} 
              placeholder="Repetir contraseña" 
            />
            <p className="error-message">{errors.repeat_password?.message}</p>
          </div>
        </div>

        {errors.general && <p className="error-message">{errors.general.message}</p>}

        {/* Checkboxes */}
        <div className="checkboxContainer">
          {/* Newsletter Checkbox */}
          <div className="inputContainer">
            <label className="checkboxLabel">
              <input type="checkbox" {...register("newsletter")} />
              Quiero recibir recomendaciones de espectáculos
            </label>
          </div>

          {/* Terms and Conditions Checkbox (Obligatorio) */}
          <div className={`inputContainer ${errors.terms ? "has-error" : ""}`}>
            <label className="checkboxLabel">
              <input
                type="checkbox"
                {...register("terms", { required: 'Debes aceptar los términos y condiciones' })}
              />
              Acepto los <a href="#">términos y condiciones</a>
            </label>
            <p className="error-message">{errors.terms?.message}</p>
          </div>
        </div>

        <button type="submit" className="apiLoginButton">CONTINUAR</button>

        <div className="backToLogin">
          <button type="button" className="backToLoginButton" onClick={goToLogin}>Ya tengo cuenta</button>
        </div>
      </form>
    </div>
  );
};

export default connect(null, {
  setLoginTokenState: setLoginToken,
})(RegisterLogin);
