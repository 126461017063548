import React, { useState, useEffect } from "react";
import axios from "axios";
import { message } from "antd";
import { endpointLogin4 } from "../../../endpoints";

const CLASS_MAP = {
  1: "music",
  2: "cinema",
  3: "theater",
  4: "others",
  5: "carnival",
  6: "party"
};

const buildInterestsFromAPI = (eventTypes, genres) => {
  const genresByType = genres.reduce((acc, genre) => {
    const typeId = parseInt(genre.type_id, 10);
    if (!acc[typeId]) acc[typeId] = [];
    acc[typeId].push({
      id: parseInt(genre.id, 10),
      name: genre.name,
    });
    return acc;
  }, {});

  return eventTypes.map((et) => ({
    id: parseInt(et.id, 10),
    name: et.name,
    className: CLASS_MAP[et.id] || "default",
    subInterests: genresByType[et.id] || [],
  }));
};

const UserInterests = ({ initialInterests = {}, loginToken, onSave, eventTypes, genres }) => {
  const [INTERESTS, setINTERESTS] = useState([]);
  const [selectedInterests, setSelectedInterests] = useState({});
  const [selectedSubInterests, setSelectedSubInterests] = useState({});
  const [isMobile, setIsMobile] = useState(false);

  // Check if the device is mobile
  useEffect(() => {
    const checkIfMobile = () => {
      setIsMobile(window.innerWidth <= 768);
    };
    
    checkIfMobile();
    window.addEventListener('resize', checkIfMobile);
    
    return () => {
      window.removeEventListener('resize', checkIfMobile);
    };
  }, []);

  useEffect(() => {
    if (Array.isArray(eventTypes) && Array.isArray(genres) && eventTypes.length && genres.length) {
      const generated = buildInterestsFromAPI(eventTypes, genres);
      setINTERESTS(generated);
    } else {
      console.warn("No hay eventTypes o genres disponibles aún.");
    }
  }, [eventTypes, genres]);

  useEffect(() => {
    if (!initialInterests || Object.keys(initialInterests).length === 0) return;

    const parsedInitials = Object.keys(initialInterests).reduce(
      (acc, key) => {
        const eventTypeId = Number(key);
        const interestData = initialInterests[key];
        acc.selected[eventTypeId] = !!interestData.isSelected;
        acc.sub[eventTypeId] = (interestData.genres || []).map(Number);
        return acc;
      },
      { selected: {}, sub: {} }
    );

    setSelectedInterests(parsedInitials.selected);
    setSelectedSubInterests(parsedInitials.sub);
  }, [initialInterests]);

  const toggleInterest = (eventTypeId) => {
    setSelectedInterests((prev) => {
      const newState = { ...prev, [eventTypeId]: !prev[eventTypeId] };
      if (newState[eventTypeId] && !selectedSubInterests[eventTypeId]) {
        setSelectedSubInterests((prevSub) => ({ ...prevSub, [eventTypeId]: [] }));
      }
      return newState;
    });
  };

  const toggleSubInterest = (eventTypeId, genreId) => {
    setSelectedSubInterests((prev) => {
      const updated = { ...prev };
      if (!updated[eventTypeId]) {
        updated[eventTypeId] = [genreId];
      } else {
        updated[eventTypeId] = updated[eventTypeId].includes(genreId)
          ? updated[eventTypeId].filter((id) => id !== genreId)
          : [...updated[eventTypeId], genreId];
      }
      return updated;
    });
  };

  const saveInterests = async () => {
    const interestsPayload = Object.keys(selectedInterests).map((eventTypeId) => ({
      eventTypeId: parseInt(eventTypeId, 10),
      isSelected: selectedInterests[eventTypeId],
      genres: selectedSubInterests[eventTypeId] || [],
    }));

    try {
      const response = await axios.post(endpointLogin4, {
        token: loginToken,
        interests: interestsPayload,
        newsletter: 1,
      });

      if (response.data.status === "ok") {
        onSave();
        message.success('Intereses actualizados con éxito');
      } else {
        console.error("Error al guardar intereses:", response.data.errorMessage);
      }
    } catch (error) {
      console.error("Error en la solicitud:", error);
    }
  };

  // Render mobile layout with interests and their corresponding subinterests
  // Modify your renderMobileLayout function
// Modify your renderMobileLayout function
const renderMobileLayout = () => {
  return INTERESTS.map((interest) => (
    <React.Fragment key={interest.id}>
      {/* Interest Card */}
      <div className="interestCard-mobile-wrapper">
        <div
          className={`interestCard ${interest.className} ${selectedInterests[interest.id] ? "selected" : ""}`}
        >
          <div className="interestIcon"></div>
          <h3>{interest.name}</h3>
          <button
            className={`interestButton ${selectedInterests[interest.id] ? "remove" : "add"}`}
            onClick={() => toggleInterest(interest.id)}
          >
            {selectedInterests[interest.id] ? "Quitar de mis intereses" : "Agregar a mis intereses"}
          </button>
        </div>
        
        {/* Always render the subinterests container, control visibility with class */}
        <div className={`mobileSubInterestSection ${selectedInterests[interest.id] ? "visible" : ""}`}>
          <div className="subInterestsGrid">
            {interest.subInterests.map((sub) => (
              <button
                key={sub.id}
                className={`subInterestButton ${selectedSubInterests[interest.id]?.includes(sub.id) ? "selected" : ""}`}
                data-category={interest.id}
                data-interest-name={interest.name}
                onClick={() => toggleSubInterest(interest.id, sub.id)}
              >
                {sub.name}
              </button>
            ))}
          </div>
        </div>
      </div>
    </React.Fragment>
  ));
};

// Modify your renderDesktopLayout function
const renderDesktopLayout = () => (
  <>
    <div className="interestsGrid">
      {INTERESTS.map((interest) => (
        <div
          key={interest.id}
          className={`interestCard ${interest.className} ${selectedInterests[interest.id] ? "selected" : ""}`}
        >
          <div className="interestIcon"></div>
          <h3>{interest.name}</h3>
          <button
            className={`interestButton ${selectedInterests[interest.id] ? "remove" : "add"}`}
            onClick={() => toggleInterest(interest.id)}
          >
            {selectedInterests[interest.id] ? "Quitar de mis intereses" : "Agregar a mis intereses"}
          </button>
        </div>
      ))}
    </div>

    {/* Always render the subinterests container, control visibility with class */}
    <div className={`subInterestsContainer ${Object.keys(selectedInterests).some(id => selectedInterests[id]) ? "visible" : ""}`}>
      {INTERESTS.map((interest) => (
        <div 
          key={interest.id} 
          className={`subInterestCategory ${selectedInterests[interest.id] ? "visible" : ""}`}
        >
          <h3>{interest.name}</h3>
          <div className="subInterestsGrid">
            {interest.subInterests.map((sub) => (
              <button
                key={sub.id}
                className={`subInterestButton ${selectedSubInterests[interest.id]?.includes(sub.id) ? "selected" : ""}`}
                data-category={interest.id}
                data-interest-name={interest.name}
                onClick={() => toggleSubInterest(interest.id, sub.id)}
              >
                {sub.name}
              </button>
            ))}
          </div>
        </div>
      ))}
    </div>
  </>
);

  return (
    <div className="interestsContainer profileCard">
      <h2 className="interestsTitle">Personaliza tus intereses</h2>

      <div className="interestsLayout">
        {isMobile ? renderMobileLayout() : renderDesktopLayout()}
      </div>

      <button className="profileSaveButton" onClick={saveInterests}>GUARDAR</button>
    </div>
  );
};

export default UserInterests;